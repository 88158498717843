import axios from './Axios'

import { axiosPrivate } from './AxiosPrivate'
// import { adminAxiosPrivate } from './AdminAxiosPrivate'


/* ------------------------------ common routes ----------------------------- */

export const createCard = (details,status) =>  axiosPrivate.post(`/createCard?status=${status}`,details)

export const createBulkCard = (details) =>  axiosPrivate.post(`/createBulkCard`,details)

export const cardProfile = (id) => axios.get(`/profileView/${id}`)  //  profile card

export const DeactiveCard = (id) => axiosPrivate.post(`/deactiveCard/${id}`)

export const AddPendingCard = (id) => axios.post(`/addPendingCard/${id}`)

export const DeleteCard = (id) => axios.post(`/deleteCard/${id}`)

export const UploadDetails = (details) => axios.post('/saveCard',details)

export const getSavedCards = () =>  axiosPrivate.get('/getSavedCard')

export const deleteSavedCard = (cardId) =>  axiosPrivate.post(`/removeBookedCard/${cardId}`)

export const getSigleCardData = (cardId) =>  axiosPrivate.get(`/getSingleCard/${cardId}`)

export const UpdateBookedCard = (cardId,datas) =>  axiosPrivate.post(`/editBookedCard/${cardId}`,datas)

/* ---------------------------------- user requests ---------------------------------- */

export const getUserCreatedCard = () =>  axiosPrivate.get('/user-createdCard')

export const checkoutDetails = (checkOutData,status) => axiosPrivate.post(`/checkout?status=${status}`,checkOutData)

// export const homepageCheckout = (checkOutData) => axiosPrivate.post('/homepage-checkout',checkOutData)
    
export const cardCheckoutDetails = (checkOutData,status) => axiosPrivate.post(`/cardCheckout?status=${status}`,checkOutData)  //public route

export const getOrders = () => axiosPrivate.get('/my-orders')

export const addToCart = (cartData) => axiosPrivate.post('/addToCart',cartData)

export const cartDatas = () => axiosPrivate.get('/get-cart')

export const cancelOrder = (id,reason) => axiosPrivate.put(`/cancel-order/${id}`,reason)

export const mailSend = (datas) => axiosPrivate.post('/sendMail',datas)

export const cartDelete = (id) => axiosPrivate.delete(`/delete-cart/${id}`) 

export const checkCoupon = (code) => axiosPrivate.post(`/check-coupon`,{code})     //both for public and private

export const pendingPayment = (id) => axiosPrivate.post(`/pending-payment/${id}`,)     //both for public and private


// export const userProfileDetails = () =>  axiosPrivate.get('/profile')

// export const userProfileDetailsUpdate = (profile) =>  axiosPrivate.put('/update-profile',profile)

// export const userChangePassword =(userId,data) =>axiosPrivate.put(`/editProfile/changePassword/${userId}`,data)




/* ----------------------------- admin requests ----------------------------- */

export const getDashboard = () => axiosPrivate.get(`/superAdmin-dashboard`)

export const getViewAdmins = () => axiosPrivate.get(`/view-admins`)

export const blockUser = (adminId) =>  axiosPrivate.put(`/block-admin/${adminId}`)

export const deleteAdmin = (adminId) =>  axiosPrivate.put(`/delete-admin/${adminId}`)

export const RestoreAdmin = (adminId) =>  axiosPrivate.put(`/restore-admin/${adminId}`)

export const getTashAdmins = () => axiosPrivate.get(`/view-trashAdmins`)

export const editAdmin = (adminId,adminData) =>  axiosPrivate.put(`/edit-admin/${adminId}`,adminData)

export const addAdmin = (adminData) => axiosPrivate.post('/createAdmin',adminData)

export const profileDetails = () =>  axiosPrivate.get('/superAdmin-profile')

export const profileDetailsUpdate = (profile) =>  axiosPrivate.put('/update-profile',profile)

export const changePassword =(userId,data) =>axiosPrivate.put(`/editProfile/changePassword/${userId}`,data)

export const forgotPassword = (mail) => axios.post(`/forgotPassword`,mail)





/* ------------------------------- auth routes ------------------------------ */

export const userSignup = (signupData) => axios.post('/signup',signupData)

export const userLogin = (loginData) => axios.post('/login',loginData)

// export const forgotPassword = (mail) => axios.post(`/forgotPassword`,mail)

export const updateNewPass =(pass,token)=>axios.put(`/resetPassword`,{pass,token})

export const userLogout = (refToken) => axios.post('/logout',{refToken})



// export const resendforgotPassword = (mail)=>axios.post(`/forgotPassword/resend`,mail)







