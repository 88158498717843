import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  headericon05,
  avatar02,
  avatar11,
  avatar17,
  headericon04,
} from "../imagepath";
import { profileDetails } from "../../utlis/data";
import { GiHamburgerMenu } from "react-icons/gi";
import { Modal } from "antd";
import logo from "../../assets/img/oncloudwifi-logo.svg";
import logosmall from "../../assets/img/logoSm.png";
import profileImage from "../../assets/img/profileImage.png";
import { userLogout } from "../../api/AdminRequest";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const Header = ({ sprAdmin }) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(profileDetails);
  const [userName, setUserName] = useState("User");
  const handleLogout = async () => {
    confirm({
    
      title: "Are you sure to Logout.",
      icon: <ExclamationCircleFilled />,
      onOk() {
        const block = async () => {
          try {
            const refToken = await localStorage.getItem("refToken");

            const { data } = await userLogout(refToken);
            if (data.success) {
              localStorage.clear();
              navigate("/");
            }
          } catch (error) {
            console.log(error.response.data);

            localStorage.clear();
            navigate("/");
          }
        };

        block();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          borderColor: 'red',
          color: 'white', // Text color
        },
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserName(user);
    document.title = profile.name;

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = profile?.logo
      ? profile?.logo
      : "https://zeeqr-files.s3.ap-south-1.amazonaws.com/assets/defaultProfile.jpg";
  }, []);

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
  };

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    maximizeBtn.addEventListener("click", handleClick);

    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
    };
  }, []);
  const iconColor = "#ffffff";
  const iconSize = "40";
  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div className="header-left">
          <Link to="/superAdmin/dashboard" className="logo">
            <img src={logo} alt="Logo" />
          </Link>
          <Link to="/superAdmin/dashboard" className="logo logo-small">
            <img src={logosmall} alt="Logo" width={30} height={30} />
          </Link>
        </div>
        {/* /Logo */}
        <div className="menu-toggle">
          <Link to="#" id="toggle_btn" onClick={handlesidebar}>
            {/* <i className="fas fa-bars" /> */}
            <GiHamburgerMenu color={iconColor} size={iconSize} />
          </Link>
        </div>

        <Link
          to="#"
          className="mobile_btn"
          id="mobile_btn"
          onClick={() => handlesidebarmobilemenu()}
        >
         <GiHamburgerMenu color="black" size={30} style={{marginTop:"16px"}} />
        </Link>
        {/* /Mobile Menu Toggle */}
        {/* Header Right Menu */}
        <ul className="nav user-menu">
          {sprAdmin ? (
            ""
          ) : (
            <li className="nav-item dropdown noti-dropdown me-2">
              <Link
                to="#"
                className="dropdown-toggle nav-link header-nav-list"
                data-bs-toggle="dropdown"
              >
                <img src={headericon05} alt="" />
              </Link>
              <div className="dropdown-menu notifications">
                <div className="topnav-dropdown-header">
                  <span className="notification-title">Notifications</span>
                  <Link to="#" className="clear-noti">
                    {" "}
                    Clear All{" "}
                  </Link>
                </div>
                <div className="noti-content">
                  <ul className="notification-list">
                    <li className="notification-message">
                      <Link to="#">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={avatar02}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              you have a message from{" "}
                              <span className="noti-title">Carlson Tech</span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                4 mins ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="#">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={avatar11}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              you have a enquiry from{" "}
                              <span className="noti-title">
                                International Software Inc
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                6 mins ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="#">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={avatar17}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              <span className="noti-title">John Hendry</span>{" "}
                              sent you a message
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                8 mins ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="topnav-dropdown-footer">
                  <Link to="#">View all Notifications</Link>
                </div>
              </div>
            </li>
          )}

          <li className="nav-item  has-arrow dropdown-heads h-16 flex pt-[20px]">
            <Link to="#" className="win-maximize maximize-icon ">
              <img src={headericon04} alt="" />
            </Link>
          </li>
          {/* User Menu */}
          <li className="nav-item dropdown has-arrow new-user-menus">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span className="user-img">
                <img
                  className="rounded-circle"
                  src={profileImage}
                  width={31}
                  alt="Ryan Taylor"
                />
                <div className="user-text">
                  <h6>{userName}</h6>
                  {/* <p className="text-muted mb-0">Administrator</p> */}
                </div>
              </span>
            </Link>
            <div className="dropdown-menu">
              <div className="user-header">
                <div className="avatar avatar-sm">
                  <img
                   src={profileImage}
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="user-text">
                  <h6>{userName}</h6>
                  {/* <p className="text-muted mb-0">Administrator</p> */}
                </div>
              </div>
              <Link className="dropdown-item" to="/superAdmin/profile">
                My Profile
              </Link>
              {/* <Link className="dropdown-item" to="/inbox">
                Inbox
              </Link> */}
              <Link className="dropdown-item" onClick={handleLogout}>
                Logout
              </Link>
            </div>
          </li>
          {/* /User Menu */}
        </ul>
        {/* /Header Right Menu */}
      </div>
      {/* /Header */}
    </>
  );
};

export default Header;
