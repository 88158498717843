import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { TbUsersGroup } from "react-icons/tb";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { RiAdminLine } from "react-icons/ri";
import { AiOutlineTeam } from "react-icons/ai";
import { getDashboard } from "../../../api/AdminRequest";
import TopServiceList from "./TopServiceList";

const Dashboard = () => {
  const [guestCount, setGuestCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [expireAdmin,setExpireAdmin] = useState([]);
 
  const [serviceMonthly, setServiceMonthly] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [guestMonthly, setGuestMonthly] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [userName, setUserName] = useState("User");

  const [data, setData] = useState({
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      fill: false,
    },
    dataLabels: {
      enabled: false,
    },
    datasets: {
      id: "apaxcharts-line",
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#3D5EE1", "#70C4CF"],
    borderWidth: 3,
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  });
  const [serviceChart, setServiceChart] = useState([
    {
      name: "Service",
      data: serviceMonthly,
    },
  ]);

  // Student Chart

  const [dataBar, object] = useState({
    colors: ["#3D5EE1", "#70C4CF"],
    chart: {
      type: "bar",
      height: 350,
      width: "100%",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        categoryGap: "30%",
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],

    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#777",
          },
        },
      },
      title: {
        text: "",
        align: "left",
        style: {
          fontSize: "18px",
        },
      },
    },
  });

  const [guestchart, setGuestChart] = useState([
    {
      name: "Guest",
      type: "column",
      data: guestMonthly,
    },
  ]);

  const iconColor = "#0d6efd";
  const iconSize = "40";

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserName(user);
    const getDetails = async () => {
      try {
        const { data } = await getDashboard();
        if (data?.success) {
          console.log("all data", data);
          setGuestCount(data?.guestCount);
          setAdminCount(data?.adminCount);
          setServiceCount(data?.serviceCount);
          setUserCount(data?.userCount);
          setServiceMonthly(data?.monthlyServiceArray);
          setGuestMonthly(data?.monthlyGuestArray);
          setExpireAdmin(data?.admins);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getDetails();
  }, []);
  useEffect(() => {
    setGuestChart([
      {
        name: "Guest",
        type: "column",
        data: guestMonthly,
      },
    ]);
    setServiceChart([
      {
        name: "Service",
        data: serviceMonthly,
      },
    ]);
    
  }, [serviceMonthly, guestMonthly]);

  return (
    <>
      {/* Page Wrapper */}

      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Welcome {userName}!</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admindashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        {/* Overview Section */}
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Total Guest</h6>
                    <h3>{guestCount}</h3>
                  </div>

                  <TbUsersGroup color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Total Admin</h6>
                    <h3>{adminCount}</h3>
                  </div>
                  <RiAdminLine color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Total Service</h6>
                    <h3>{serviceCount}</h3>
                  </div>
                  <MdOutlineBusinessCenter color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Total User</h6>
                    <h3>{userCount}</h3>
                  </div>
                  <AiOutlineTeam color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Overview Section */}
        <div className="row">
          <div className="col-md-12 col-lg-6">
            {/* Revenue Chart */}
            <div className="card card-chart">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h5 className="card-title">Service Created</h5>
                  </div>
                  <div className="col-6">
                    <ul className="chart-list-out">
                      <li>
                        <span className="circle-blue" />
                        Service
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="apexcharts-area"></div>
                <Chart options={data} series={serviceChart} type="line" />
              </div>
            </div>
            {/* /Revenue Chart */}
          </div>
          <div className="col-md-12 col-lg-6">
            {/* Student Chart */}
            <div className="card card-chart">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h5 className="card-title">Guest Monthly View </h5>
                  </div>
                  <div className="col-6">
                    <ul className="chart-list-out">
                      <li>
                        <span className="circle-green" />
                        Guest
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="apexcharts-area"></div>
                <Chart options={dataBar} series={guestchart} type="line" />
              </div>
            </div>

            {/* /Student Chart */}
          </div>
        </div>
        <TopServiceList serviceProp={expireAdmin} />
      </div>
      {/* Footer */}

      {/* /Main Wrapper */}
    </>
  );
};

export default Dashboard;
