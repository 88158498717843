import React from 'react'
import Home from '../../components/common/home/home'
function Homepage() {
  return (
    <div>
        <Home />
</div>

  )
}

export default Homepage