import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginProtect from "./auth/UserLoginProtect";
import SuperAdminRouteProtect from "./auth/SuperAdminRouteProtect";
import Homepage from "./pages/common/Homepage";
import AdminTrashPage from "./pages/superAdminPages/AdminTrashListPage";
import LoginPage from "./pages/common/LoginPage";
import SuperAdminDashbordPage from "./pages/superAdminPages/SuperAdminDashbordPage";
import AddAdminPage from "./pages/superAdminPages/AddAdminPage";
import AdminListPage from "./pages/superAdminPages/AdminListPage";
import EditAdminPage from "./pages/superAdminPages/EditAdminPage";
import ProfilePage from "./pages/adminPages/ProfilePage";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<LoginProtect />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<Homepage />} />
        </Route>
        <Route element={<SuperAdminRouteProtect />}>
          <Route
            path="/superAdmin/dashboard"
            element={<SuperAdminDashbordPage />}
          />
          <Route path="/superAdmin/adminList" element={<AdminListPage />} />
          <Route path="/superAdmin/addAdmin" element={<AddAdminPage />} />
          <Route path="/superAdmin/editAdmin" element={<EditAdminPage />} />
          <Route path="/superAdmin/adminTrash" element={<AdminTrashPage />} />
          <Route path="/superAdmin/profile" element={<ProfilePage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
