import React from "react";
import { Link } from "react-router-dom";
// import Styles from "./styles.module.scss";
import mainBg from "../../../assets/img/mainBg.webp";
import logo from "../../../assets/img/oncloudwifi-logo-white.svg";
import "antd/dist/reset.css";

function home() {
  return (
    <div>
      <body>
        <div className="">
          <img className="" src={mainBg}></img>

          <div className="">
            <div className="">
              <h1>
                <img src={logo}/>
              </h1>

              <div>
                <div>
                  <p className="">
                    Empower your guest WiFi experience
                  </p>
                </div>

                <div>
                  <p className="">
                    The enterprise guest WiFi solution with analytics
                    capabilities. Discover your customers behaviour and improve
                    their satsification.
                  </p>
                </div>
              </div>
              <div>
                <p className="">
                  Please{" "}
                  <Link
                    to={"/login"}
                    className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white border-transparent  bg-black mt-4 lg:mt-0"
                  >
                    Login
                  </Link>
                  to your account or contact us for more detais
                  <a> sales@metasoft.ae</a> .
                </p>
              </div>

              <div>
                <p className="">Powered by onCloudWiFi .</p>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default home;
