import React from 'react'
import AdminList from '../../components/superAdmin/admins/AdminList'
import Header from "../../components/header/Header";
import Sidebar from "../../components/superAdmin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
function AdminListPage() {
  return (
    <div className="main-wrapper">
    <Header sprAdmin={true} />

    <Sidebar />
    <div className="page-wrapper">
      <AdminList />
      <Footer />
    </div>
  </div>
  )
}

export default AdminListPage