import React from "react";
import Dashboard from "../../components/superAdmin/dashboard/Dashboard";
import { useLocation } from "react-router-dom";
import Header from "../../components/header/Header";
import Sidebar from "../../components/superAdmin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";

function SuperAdminDashbordPage() {
  const location = useLocation();
  const user = location.state?.user;
  console.log("user", user);
  return (
    <div className="main-wrapper">
      <Header sprAdmin={true} />

      <Sidebar />
      <div className="page-wrapper">
        <Dashboard />
        <Footer />
      </div>
    </div>
  );
}

export default SuperAdminDashbordPage;
