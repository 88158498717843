import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineTeam, AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiTrashAlt } from "react-icons/bi";

const Sidebar = () => {
  const loction = useLocation();

  useEffect(() => {
    function handleMouseOver(e) {
      e.stopPropagation();
      if (
        document.body.classList.contains("mini-sidebar") &&
        document.querySelector("#toggle_btn").offsetParent !== null
      ) {
        var targ = e.target.closest(".sidebar");
        if (targ) {
          document.body.classList.add("expand-menu");
          document
            .querySelectorAll(".subdrop + ul")
            .forEach((ul) => (ul.style.display = "block"));
        } else {
          document.body.classList.remove("expand-menu");
          document
            .querySelectorAll(".subdrop + ul")
            .forEach((ul) => (ul.style.display = "none"));
        }
        return false;
      }
    }

    document.addEventListener("mouseover", handleMouseOver);

    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);

  let pathName = loction.pathname;

  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="menu-title">
                <span>Main Menu</span>
              </li>

              <li
                className={`${
                  "/superAdmin/dashboard" === pathName ? "active" : ""
                }`}
              >
                <Link to="/superAdmin/dashboard">
                  <RxDashboard />
                  <span>Dashboard</span>
                </Link>
              </li>
            </ul>

            <ul>
              <li
                className={`${
                  "/superAdmin/adminList" === pathName ? "active" : ""
                }`}
              >
                <Link to="/superAdmin/adminList">
                  <AiOutlineTeam />
                  <span>Admin List</span>
                </Link>
              </li>
            </ul>
            <ul>
              <li
                className={`${
                  "/superAdmin/addAdmin" === pathName ? "active" : ""
                }`}
              >
                <Link to="/superAdmin/addAdmin">
                  <AiOutlineUsergroupAdd />
                  <span>Add Admin</span>
                </Link>
              </li>
            </ul>
            <ul>
              <li
                className={`${
                  "/superAdmin/adminTrash" === pathName ? "active" : ""
                }`}
              >
                <Link to="/superAdmin/adminTrash">
                <BiTrashAlt/>
                  <span>Admin Trash</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
