import axios from '../api/Axios'
// import UseAxiosPrivate from '../hooks/UseAxiosPrivate'

import { axiosPrivate } from './AxiosPrivate'

export const userSignup = (signupData) => axios.post('/signup',signupData)

export const userLogin = (loginData) => axios.post('/login',loginData)

export const addUser = (serviceId,userData) => axiosPrivate.post(`/createUser/${serviceId}`,userData)

export const getViewUser = () => axiosPrivate.get(`/view-user`)

export const editUser= (userId,userData) =>  axiosPrivate.put(`/edit-user/${userId}`,userData)

export const deleteUser = (userId) =>  axiosPrivate.put(`/delete-user/${userId}`)
